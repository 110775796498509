<template>
  <div>
    <a-card title="工序检验">
      <a-row gutter="16">
        <a-col :span="24" style="width: 240px; margin-bottom: 12px">
          <a-input-search v-model="searchForm.search" placeholder="工序检验单号, 生产工单号" allowClear @search="search" />
        </a-col>
        <a-col :span="24" style="width: 200px; margin-bottom: 12px">
          <a-select v-model="searchForm.inspector" placeholder="检验人" allowClear style="width: 100%" @change="search">
            <a-select-option v-for="item in userItems" :key="item.id" :value="item.id">{{ item.name }} </a-select-option>
          </a-select>
        </a-col>
        <a-col :span="24" style="width: 100px; margin-bottom: 12px">
          <a-button type="primary" icon="search" @click="search">查询</a-button>
        </a-col>

        <div style="margin-bottom: 12px; float: right">
          <a-button type="primary" icon="plus" style="margin: 0 8px" @click="handleAdd">新增工序检验</a-button>
        </div>
      </a-row>

      <a-row style="margin-top: 12px">
        <a-table
          rowKey="id"
          size="small"
          :columns="columns"
          :dataSource="dataItems"
          :loading="loading"
          :pagination="pagination"
          @change="tableChange"
        >
          <template slot="number" slot-scope="value, item">
            <a @click="jumpInspectionReportDetail(item)">
              {{ item.number }}
            </a>
          </template>

          <template slot="production_order_number" slot-scope="value, item">
            <a @click="jumpProductionOrderDetail(item)">
              {{ item.production_order_number }}
            </a>
          </template>

          <template slot="action" slot-scope="value, item">
            <TableAction :item="item" @update="handleUpdate" @delete="handleDelete" />
          </template>
        </a-table>
      </a-row>
    </a-card>
  </div>
</template>

<script>
import { processInspectionReportList } from "@/api/inspection";
import { userOption } from "@/api/option";

export default {
  components: {
    TableAction: () => import("./TableAction"),
  },
  data() {
    return {
      columns: [
        {
          title: "序号",
          dataIndex: "index",
          width: 60,
          customRender: (_, __, index) => index + 1,
        },
        {
          title: "工序检验单号",
          dataIndex: "number",
          width: 160,
          scopedSlots: { customRender: "number" },
        },
        {
          title: "生产工单号",
          dataIndex: "production_order_number",
          width: 160,
          scopedSlots: { customRender: "production_order_number" },
        },
        {
          title: "检验工序",
          dataIndex: "inspection_process",
        },
        {
          title: "检验日期",
          dataIndex: "inspection_date",
        },
        {
          title: "检验人",
          dataIndex: "inspector",
          customRender: (_, item) => item.inspector_name,
        },
        {
          title: "检验结果",
          dataIndex: "inspection_result_display",
        },
        {
          title: "合格数量",
          dataIndex: "qualified_quantity",
        },
        {
          title: "不合格数量",
          dataIndex: "unqualified_quantity",
        },
        {
          title: "备注",
          dataIndex: "remark",
        },
        {
          title: "创建人",
          dataIndex: "creator_name",
        },
        {
          title: "创建时间",
          dataIndex: "create_time",
        },
        {
          title: "操作",
          dataIndex: "action",
          width: 100,
          scopedSlots: { customRender: "action" },
        },
      ],
      searchForm: { search: "", page: 1, page_size: 10 },
      pagination: { current: 1, total: 0, pageSize: 10 },
      loading: false,
      dataItems: [],
      userItems: [],
    };
  },
  computed: {},
  methods: {
    initData() {
      this.list();

      userOption({ page_size: 99999 }).then((resp) => {
        this.userItems = resp.results;
      });
    },
    list() {
      this.loading = true;
      processInspectionReportList(this.searchForm)
        .then((data) => {
          this.pagination.total = data.count;
          this.dataItems = data.results;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    handleUpdate(item) {
      this.dataItems = this.$functions.replaceItem(this.dataItems, item);
    },
    handleDelete(item) {
      this.dataItems = this.$functions.removeItem(this.dataItems, item);
    },
    search() {
      this.searchForm.page = 1;
      this.pagination.current = 1;
      this.list();
    },
    tableChange(pagination, filters, sorter) {
      this.searchForm.page = pagination.current;
      this.pagination.current = pagination.current;
      if (sorter.field) {
        this.searchForm.ordering = `${sorter.order == "descend" ? "-" : ""}${sorter.field}`;
      }
      this.list();
    },
    jumpProductionOrderDetail(item) {
      this.$router.push({ path: "/production/detial", query: { id: item.production_order } });
    },
    jumpInspectionReportDetail(item) {
      this.$router.push({ path: "/inspection/process_inspection_report_detail", query: { id: item.id } });
    },
    handleAdd() {
      this.$router.push({ path: "/inspection/process_inspection_report_create" });
    },
  },
  mounted() {
    this.initData();
  },
};
</script>
